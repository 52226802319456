var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Phaser from "phaser";
import { ICON_KEYS } from "../../config";
import DetectionCircle from "../GameUtils/DetectionCircle";
export var AntState;
(function (AntState) {
    AntState["MARCHING_TO_SAFE_ZONE"] = "MARCHING_TO_SAFE_ZONE";
    AntState["WAYPOINT_NOTICED"] = "WAYPOINT_NOTICED";
    AntState["MARCHING_TO_WAYPOINT"] = "MARCHING_TO_WAYPOINT";
    AntState["RECIEVING_ATTACK"] = "RECIEVING_ATTACK";
    AntState["RECIEVING_DAMAGE"] = "RECIEVING_DAMAGE";
    AntState["DYING"] = "DYING";
    AntState["DEAD"] = "DEAD";
    AntState["LOST"] = "LOST";
})(AntState || (AntState = {}));
var Ant = /** @class */ (function (_super) {
    __extends(Ant, _super);
    function Ant(scene, x, y, speed, health, antState, detectionCircleRadius) {
        if (speed === void 0) { speed = 100; }
        if (health === void 0) { health = 100; }
        if (antState === void 0) { antState = AntState.MARCHING_TO_SAFE_ZONE; }
        if (detectionCircleRadius === void 0) { detectionCircleRadius = 50; }
        var _this = _super.call(this, scene, x, y, ICON_KEYS.ANT) || this;
        _this._currentWaypointIndex = 0;
        _this.attackRange = 50;
        _this.attackDamage = 10;
        _this.isInvincible = false;
        scene.add.existing(_this);
        scene.physics.add.existing(_this);
        _this.speed = speed;
        _this.health = health;
        _this.antState = antState;
        _this._id = "ant-" + Math.random().toString(36).slice(2, 11);
        var opacity = _this.antState === AntState.LOST ? 0.3 : 0;
        _this.detectionCircle = new DetectionCircle(scene, x, y, detectionCircleRadius, _this, 0xadd8e6, opacity);
        return _this;
    }
    Ant.prototype.getDetectionCircle = function () {
        return this.detectionCircle;
    };
    Object.defineProperty(Ant.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Ant.prototype, "currentWaypointIndex", {
        get: function () {
            return this._currentWaypointIndex;
        },
        set: function (value) {
            this._currentWaypointIndex = value;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * CONFIG
     */
    // ACTIVE BEHAVIORS
    Ant.prototype.update = function (userWaypoints) {
        this.detectionCircle.setPosition(this.x, this.y);
        if (this.antState === AntState.DEAD) {
            return; // Do nothing if the ant is dead
        }
        switch (this.antState) {
            case AntState.LOST:
                // No active behavior
                break;
            case AntState.MARCHING_TO_SAFE_ZONE:
                this.marchToSafeZone();
                this.checkForWaypoints(userWaypoints);
                break;
            case AntState.WAYPOINT_NOTICED:
                this.startMarchingToUserWaypoint();
                break;
            case AntState.MARCHING_TO_WAYPOINT:
                this.marchToWaypoint(userWaypoints);
                break;
            case AntState.RECIEVING_ATTACK:
                // No active behavior
                break;
            case AntState.RECIEVING_DAMAGE:
                // No active behavior
                break;
            case AntState.DYING:
                this.die();
                break;
        }
    };
    /**
     * ANT BODY
     */
    Ant.prototype.die = function () {
        this.updateAntState(AntState.DEAD);
        this.destroy();
    };
    Ant.prototype.startMarchingToUserWaypoint = function () {
        this.updateAntState(AntState.MARCHING_TO_WAYPOINT);
    };
    Ant.prototype.marchToSafeZone = function () {
        this.setVelocityY(this.speed);
        this.setVelocityX(0);
    };
    Ant.prototype.marchToWaypoint = function (userWaypoints) {
        var targetWayPoint = userWaypoints[this.currentWaypointIndex];
        this.scene.physics.moveToObject(this, targetWayPoint, this.speed);
        if (this.hasReachedWaypoint(targetWayPoint)) {
            this.currentWaypointIndex += 1;
            if (this.currentWaypointIndex >= userWaypoints.length) {
                this.antState = AntState.MARCHING_TO_SAFE_ZONE;
            }
            else {
                this.antState = AntState.WAYPOINT_NOTICED;
            }
        }
    };
    Ant.prototype.hasReachedWaypoint = function (targetWayPoint) {
        if (this.antState === AntState.DEAD) {
            return false;
        }
        if (!this.body)
            throw new Error("Ant body is null");
        return (Phaser.Math.Distance.Between(this.body.center.x, this.body.center.y, targetWayPoint.x, targetWayPoint.y) < 10);
    };
    /**
     * ANT SENSES / INTERACTIONS WITH ENVIRONMENT
     */
    Ant.prototype.receiveAttack = function (amount, attacker) {
        if (this.antState === AntState.DEAD) {
            return true;
        }
        if (!this.body)
            throw new Error("Ant body is null");
        this.updateAntState(AntState.RECIEVING_ATTACK);
        // For now we just automatically take damage
        this.updateAntState(AntState.RECIEVING_DAMAGE);
        var isDead = this.receiveDamage(amount, attacker);
        return isDead;
    };
    Ant.prototype.receiveDamage = function (amount, attacker) {
        var _this = this;
        if (this.antState === AntState.DEAD) {
            return true;
        }
        this.health -= amount;
        console.log("Ant health", this.health);
        var isDead = this.health <= 0;
        if (isDead) {
            this.updateAntState(AntState.DYING);
            return isDead;
        }
        // Knock back the ant by applying a force in the opposite direction of the attack
        var direction = new Phaser.Math.Vector2(this.x - attacker.x, this.y - attacker.y).normalize();
        var knockbackForce = amount * 20;
        this.setVelocity(direction.x * knockbackForce, direction.y * knockbackForce);
        // Apply drag to slow down the ant after knockback
        this.body.setDrag(750, 750);
        // Reset drag after a short duration to allow normal movement
        this.scene.time.delayedCall(500, function () {
            if (_this.antState === AntState.DEAD) {
                return;
            }
            _this.body.setDrag(0, 0);
            _this.updateAntState(AntState.MARCHING_TO_SAFE_ZONE);
        });
        return isDead;
    };
    Ant.prototype.checkForWaypoints = function (waypoints) {
        if (this.currentWaypointIndex < waypoints.length) {
            this.updateAntState(AntState.WAYPOINT_NOTICED);
        }
    };
    Ant.prototype.becomeFound = function (ant) {
        var _this = this;
        if (this.antState !== AntState.LOST) {
            return;
        }
        this.detectionCircle.setAlpha(0);
        this.currentWaypointIndex = ant.currentWaypointIndex;
        this.scene.time.delayedCall(250, function () {
            _this.updateAntState(AntState.MARCHING_TO_SAFE_ZONE);
        });
    };
    /**
     * ANT STATE
     */
    Ant.prototype.updateAntState = function (newState) {
        var invalidTransitions = {
            MARCHING_TO_SAFE_ZONE: [],
            WAYPOINT_NOTICED: [],
            MARCHING_TO_WAYPOINT: [],
            RECIEVING_ATTACK: [],
            RECIEVING_DAMAGE: [AntState.MARCHING_TO_WAYPOINT],
            DYING: [AntState.MARCHING_TO_WAYPOINT],
            DEAD: [AntState.WAYPOINT_NOTICED],
            LOST: [],
        };
        if (newState === this.antState) {
            // console.warn("Redundant attempt to update game state", newState);
            return;
        }
        if (invalidTransitions[this.antState].includes(newState)) {
            // console.warn(
            //   `Invalid transition attempt from ${this.fireAntState} to ${newState}`
            // );
            return;
        }
        if (this.antState === AntState.DEAD) {
            console.log("Ant is dead, cannot update state");
            return;
        }
        this.antState = newState;
        console.log("Updating Ant state", newState);
        // console.log(Object.values(AntState));
        // this.scene.events.emit(CUSTOM_EVENTS.GAME_STATE_UPDATED, this.fireAntState);
    };
    return Ant;
}(Phaser.Physics.Arcade.Sprite));
export default Ant;
