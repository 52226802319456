var CollisionManager = /** @class */ (function () {
    function CollisionManager(scene, antManager, environmentManager, gameManager, uiManager) {
        this.scene = scene;
        this.antManager = antManager;
        this.environmentManager = environmentManager;
        this.gameManager = gameManager;
        this.uiManager = uiManager;
    }
    CollisionManager.prototype.create = function () {
        this.fireAntVision();
        this.fireAntLungeSenses();
        this.fireAntAttackSenses();
        this.setUpObstacleCollisions();
        this.setUpLostAntCollisions();
    };
    CollisionManager.prototype.setUpObstacleCollisions = function () {
        var _this = this;
        this.scene.physics.add.collider(this.antManager.getAnts(), this.environmentManager.getObstacles());
        this.scene.physics.add.collider(this.environmentManager.getFireAnts(), this.environmentManager.getObstacles());
        this.scene.physics.add.collider(this.antManager.getAnts(), this.environmentManager.getSafezones(), function (_, ant) {
            _this.antManager.saveAnt(ant);
            _this.gameManager.antSaved();
        });
    };
    CollisionManager.prototype.setUpLostAntCollisions = function () {
        var _this = this;
        this.environmentManager
            .getLostAnts()
            .getChildren()
            .forEach(function (ant) {
            _this.scene.physics.add.overlap(ant.getDetectionCircle(), _this.antManager.getAnts(), _this
                .handleLostAntDetection, undefined, _this);
        });
    };
    CollisionManager.prototype.handleLostAntDetection = function (lostAntDetectionCircle, ant) {
        if (this.environmentManager.getLostAnts().getChildren().length === 0) {
            return;
        }
        console.log("Ant circle: ", lostAntDetectionCircle);
        console.log("Ant: ", ant);
        var lostAntInstance = lostAntDetectionCircle.parent;
        var antInstance = ant;
        lostAntInstance.becomeFound(antInstance);
        this.antManager.addLostAnt(lostAntInstance);
        this.environmentManager.removeLostAnt(lostAntInstance);
        this.gameManager.incrementColonySize();
    };
    CollisionManager.prototype.fireAntVision = function () {
        var _this = this;
        this.environmentManager
            .getFireAnts()
            .getChildren()
            .forEach(function (FireAnt) {
            _this.scene.physics.add.overlap(FireAnt.getDetectionCircle(), _this.antManager.getAnts(), _this
                .handleFireAntVisionDetection, undefined, _this);
        });
    };
    CollisionManager.prototype.handleFireAntVisionDetection = function (fireAntDetectionCircle, ant) {
        var fireAntInstance = fireAntDetectionCircle.parent;
        var antInstance = ant;
        fireAntInstance.senseAntInVisionRange(antInstance);
    };
    CollisionManager.prototype.fireAntLungeSenses = function () {
        var _this = this;
        this.environmentManager
            .getFireAnts()
            .getChildren()
            .forEach(function (FireAnt) {
            _this.scene.physics.add.overlap(FireAnt.getLungeCircle(), _this.antManager.getAnts(), _this
                .handleFireAntLungeDetection, undefined, _this);
        });
    };
    CollisionManager.prototype.handleFireAntLungeDetection = function (fireAntDetectionCircle, ant) {
        var fireAntInstance = fireAntDetectionCircle.parent;
        var antInstance = ant;
        fireAntInstance.senseAntInLungeRange(antInstance);
    };
    CollisionManager.prototype.fireAntAttackSenses = function () {
        var _this = this;
        this.environmentManager
            .getFireAnts()
            .getChildren()
            .forEach(function (FireAnt) {
            _this.scene.physics.add.overlap(FireAnt.getAttackCircle(), _this.antManager.getAnts(), _this
                .handleFireAntAttackDetection, undefined, _this);
        });
    };
    CollisionManager.prototype.handleFireAntAttackDetection = function (fireAntAttackCircle, ant) {
        var fireAntInstance = fireAntAttackCircle.parent;
        var antInstance = ant;
        fireAntInstance.senseAntInAttackRange(antInstance);
    };
    return CollisionManager;
}());
export default CollisionManager;
