var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Phaser from "phaser";
import background from "../../public/assets/backgrounds/background-white.png";
import ant from "../../public/assets/sprites/ant.png";
import waypoint from "../../public/assets/sprites/waypoint.png";
import GameManager from "../GameManagement/GameManager";
import fireAnt from "../../public/assets/sprites/fire_ant.png";
import EnvironmentManager from "../GameObjects/Environment/EnvironmentManager";
import DebugUtility from "../Utils/DebugUtility";
import { ICON_KEYS, SCENES } from "../config";
import UIManager from "../GameManagement/UIManager";
import CollisionManager from "../GameObjects/Environment/CollisionManager";
import AntManager from "../GameObjects/Player/AntManager";
var GamePlayScene = /** @class */ (function (_super) {
    __extends(GamePlayScene, _super);
    function GamePlayScene() {
        var _this = _super.call(this, SCENES.GAME_PLAY_SCENE) || this;
        _this.gameManager = new GameManager(_this);
        _this.uiManager = new UIManager(_this, _this.gameManager);
        _this.environmentManager = new EnvironmentManager(_this);
        _this.antManager = new AntManager(_this);
        _this.debugUtility = new DebugUtility(_this);
        _this.collisionManager = new CollisionManager(_this, _this.antManager, _this.environmentManager, _this.gameManager, _this.uiManager);
        return _this;
    }
    GamePlayScene.prototype.init = function () {
        this.gameManager.init();
        var levelConfig = this.gameManager.getLevelConfig();
        this.environmentManager.init();
        this.antManager.init(levelConfig);
        this.uiManager.initForGamePlay(levelConfig);
    };
    GamePlayScene.prototype.preload = function () {
        this.load.image("background", background);
        this.load.image(ICON_KEYS.ANT, ant);
        this.load.image(ICON_KEYS.WAYPOINT, waypoint);
        this.load.image(ICON_KEYS.FIRE_ANT, fireAnt);
    };
    GamePlayScene.prototype.create = function () {
        this.debugUtility.enableDebugMode();
        this.gameManager.create();
        var levelConfig = this.gameManager.getLevelConfig();
        this.environmentManager.create(levelConfig);
        this.antManager.create(levelConfig);
        this.uiManager.createForGamePlay();
        this.collisionManager.create();
    };
    GamePlayScene.prototype.update = function () {
        this.gameManager.update();
        var gameState = this.gameManager.gameStatus.gameState;
        this.debugUtility.updateDebugInfo(this.gameManager);
        if (gameState === "IN_PROGRESS") {
            this.antManager.update();
            this.environmentManager.update();
        }
        if (this.gameManager.gameStatus.gameState === "NEXT_LEVEL_READY") {
            this.scene.start(SCENES.GAME_PLAY_SCENE);
        }
        else if (this.gameManager.gameStatus.gameState === "GAME_COMPLETED") {
            var data = {
                score: this.gameManager.gameStatus.savedAnts,
            };
            this.scene.start(SCENES.END_SCENE, data);
        }
    };
    return GamePlayScene;
}(Phaser.Scene));
export default GamePlayScene;
