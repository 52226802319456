var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Phaser from "phaser";
var DetectionCircle = /** @class */ (function (_super) {
    __extends(DetectionCircle, _super);
    function DetectionCircle(scene, x, y, radius, parent, color, transparency) {
        if (color === void 0) { color = 0xff0000; }
        if (transparency === void 0) { transparency = 0.1; }
        var _this = _super.call(this, scene, x, y, radius, 0, 360, false, color, transparency) || this;
        _this.parent = parent;
        scene.add.existing(_this);
        scene.physics.add.existing(_this);
        return _this;
    }
    return DetectionCircle;
}(Phaser.GameObjects.Arc));
export default DetectionCircle;
