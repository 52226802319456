import FireAnt, { FireAntState } from "./FireAnt";
import Ant, { AntState } from "../Player/Ant";
var EnvironmentManager = /** @class */ (function () {
    function EnvironmentManager(scene) {
        this.obstacles = [];
        this.safezones = [];
        this.scene = scene;
    }
    EnvironmentManager.prototype.init = function () {
        this.obstacles = [];
        this.safezones = [];
        this.fireAnts = this.scene.physics.add.group({
            classType: FireAnt,
        });
        this.lostAnts = this.scene.physics.add.group({
            classType: Ant,
        });
    };
    EnvironmentManager.prototype.create = function (levelConfig) {
        this.createObstacles(levelConfig);
        this.createSafezones(levelConfig);
        this.createFireAnts(levelConfig);
        this.createLostAnts(levelConfig);
    };
    EnvironmentManager.prototype.update = function () {
        this.fireAnts.getChildren().forEach(function (fireAnt) {
            fireAnt.update();
        });
    };
    EnvironmentManager.prototype.createLostAnts = function (levelConfig) {
        var _this = this;
        if (!levelConfig.lostAnts)
            return;
        levelConfig.lostAnts.forEach(function (ant) {
            var newLostAnt = new Ant(_this.scene, ant.x, ant.y, levelConfig.ants.speed, 100, AntState.LOST);
            _this.lostAnts.add(newLostAnt);
        });
    };
    EnvironmentManager.prototype.createObstacles = function (levelConfig) {
        var _this = this;
        levelConfig.obstacles.forEach(function (obstacleConfig) {
            var newObstacle = _this.scene.add.rectangle(obstacleConfig.x, obstacleConfig.y, obstacleConfig.width, obstacleConfig.height, 0x000000);
            _this.scene.physics.add.existing(newObstacle, true);
            _this.obstacles.push(newObstacle);
        });
    };
    EnvironmentManager.prototype.getObstacles = function () {
        return this.obstacles;
    };
    EnvironmentManager.prototype.getLostAnts = function () {
        return this.lostAnts;
    };
    EnvironmentManager.prototype.removeLostAnt = function (ant) {
        this.lostAnts.remove(ant);
    };
    EnvironmentManager.prototype.createFireAnts = function (levelConfig) {
        var _this = this;
        var _a;
        this.fireAnts = this.scene.physics.add.group({
            classType: FireAnt,
        });
        (_a = levelConfig.fireAntConfig) === null || _a === void 0 ? void 0 : _a.startingFireAnts.forEach(function (fireAnt) {
            var _a, _b, _c, _d, _e;
            var newFireAnt = new FireAnt(_this.scene, fireAnt.x, fireAnt.y, (_a = levelConfig.fireAntConfig) === null || _a === void 0 ? void 0 : _a.speed, (_b = levelConfig.fireAntConfig) === null || _b === void 0 ? void 0 : _b.health, FireAntState.WAITING_FOR_TARGET, (_c = levelConfig.fireAntConfig) === null || _c === void 0 ? void 0 : _c.visionRange, (_d = levelConfig.fireAntConfig) === null || _d === void 0 ? void 0 : _d.attackDamage).setScale(0.5);
            (_e = _this.fireAnts) === null || _e === void 0 ? void 0 : _e.add(newFireAnt);
        });
    };
    EnvironmentManager.prototype.getFireAnts = function () {
        return this.fireAnts;
    };
    EnvironmentManager.prototype.createSafezones = function (levelConfig) {
        var bottomWall = this.scene.add.rectangle(this.scene.scale.width / 2, this.scene.scale.height - 10, this.scene.scale.width, 20, 0x000000);
        this.scene.physics.add.existing(bottomWall, true);
        this.safezones.push(bottomWall);
        var text = this.scene.add.text(this.scene.scale.width / 2, this.scene.scale.height - 10, "HOME THIS WAY!", {
            fontSize: "20px",
            color: "#ffffff",
        });
        text.setOrigin(0.5, 0.5);
    };
    EnvironmentManager.prototype.getSafezones = function () {
        return this.safezones;
    };
    return EnvironmentManager;
}());
export default EnvironmentManager;
