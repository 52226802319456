import { LEVELS } from "../Services/levels.service";
import { CUSTOM_EVENTS } from "../config";
var GameStateOptions = [
    "IN_PROGRESS",
    "PAUSED",
    "CURRENT_LEVEL_COMPLETED",
    "NEXT_LEVEL_READY",
    "GAME_COMPLETED",
];
var GameManager = /** @class */ (function () {
    function GameManager(scene) {
        this.gameState = "PAUSED";
        this._colonySize = 1;
        this.savedAnts = 0;
        this.scene = scene;
        this.currentLevel = 0;
    }
    Object.defineProperty(GameManager.prototype, "colonySize", {
        get: function () {
            return this._colonySize;
        },
        enumerable: false,
        configurable: true
    });
    GameManager.prototype.init = function () {
        this.updateGameState("PAUSED");
        this.levelConfig = LEVELS[this.currentLevel];
        this.savedAnts = 0;
    };
    GameManager.prototype.create = function () {
        // this.setupUI();
    };
    GameManager.prototype.update = function () {
        if (this.savedAnts >= this.colonySize) {
            this.updateGameState("CURRENT_LEVEL_COMPLETED");
        }
    };
    GameManager.prototype.advanceOneLevel = function () {
        this.currentLevel += 1;
        if (this.currentLevel >= LEVELS.length) {
            this.updateGameState("GAME_COMPLETED");
            this.currentLevel = 0;
            return -1;
        }
        this.updateGameState("NEXT_LEVEL_READY");
        return this.currentLevel;
    };
    GameManager.prototype.startLevel = function () {
        this.updateGameState("IN_PROGRESS");
    };
    GameManager.prototype.resetLevel = function () {
        this.scene.scene.restart();
    };
    GameManager.prototype.getCurrentLevel = function () {
        return this.currentLevel;
    };
    GameManager.prototype.getLevelConfig = function () {
        return LEVELS[this.currentLevel];
    };
    Object.defineProperty(GameManager.prototype, "gameStatus", {
        get: function () {
            return {
                gameState: this.gameState,
                currentLevel: this.currentLevel,
                savedAnts: this.savedAnts,
                colonySize: this.colonySize,
            };
        },
        enumerable: false,
        configurable: true
    });
    GameManager.prototype.incrementColonySize = function () {
        this._colonySize += 1;
        this.scene.events.emit(CUSTOM_EVENTS.COLONY_STATUS_UPDATED, this.savedAnts, this.colonySize);
    };
    GameManager.prototype.decrementColonySize = function () {
        this._colonySize -= 1;
        this.scene.events.emit(CUSTOM_EVENTS.COLONY_STATUS_UPDATED, this.savedAnts, this.colonySize);
    };
    GameManager.prototype.antSaved = function () {
        this.savedAnts += 1;
        this.scene.events.emit(CUSTOM_EVENTS.COLONY_STATUS_UPDATED, this.savedAnts, this.colonySize);
    };
    GameManager.prototype.updateGameState = function (newState) {
        var invalidTransitions = {
            IN_PROGRESS: [],
            PAUSED: [],
            CURRENT_LEVEL_COMPLETED: [],
            NEXT_LEVEL_READY: ["CURRENT_LEVEL_COMPLETED"],
            GAME_COMPLETED: ["CURRENT_LEVEL_COMPLETED"],
        };
        if (newState === this.gameState) {
            console.warn("Redundant attempt to update game state", newState);
            return;
        }
        if (invalidTransitions[this.gameState].includes(newState)) {
            console.warn("Invalid transition attempt from ".concat(this.gameState, " to ").concat(newState));
            return;
        }
        this.gameState = newState;
        console.log("Updating game state", newState);
        this.scene.events.emit(CUSTOM_EVENTS.GAME_STATE_UPDATED, this.gameState);
    };
    return GameManager;
}());
export default GameManager;
