var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Phaser from "phaser";
import { ICON_KEYS } from "../../config";
import DetectionCircle from "../GameUtils/DetectionCircle";
export var FireAntState;
(function (FireAntState) {
    FireAntState["WAITING_FOR_TARGET"] = "WAITING_FOR_TARGET";
    FireAntState["TARGET_FOUND"] = "TARGET_FOUND";
    FireAntState["CRAWLING_TO_TARGET"] = "CRAWLING_TO_TARGET";
    FireAntState["TARGET_IN_LUNGE_RANGE"] = "TARGET_IN_LUNGE_RANGE";
    FireAntState["LUNGING_TO_TARGET"] = "LUNGING_TO_TARGET";
    FireAntState["TARGET_IN_ATTACK_RANGE"] = "TARGET_IN_ATTACK_RANGE";
    FireAntState["ATTACKING_TARGET"] = "ATTACKING_TARGET";
})(FireAntState || (FireAntState = {}));
var FireAnt = /** @class */ (function (_super) {
    __extends(FireAnt, _super);
    function FireAnt(scene, x, y, speed, health, fireAntState, visionRange, attackDamage) {
        if (speed === void 0) { speed = 100; }
        if (health === void 0) { health = 100; }
        if (fireAntState === void 0) { fireAntState = FireAntState.WAITING_FOR_TARGET; }
        if (visionRange === void 0) { visionRange = 50; }
        if (attackDamage === void 0) { attackDamage = 10; }
        var _this = _super.call(this, scene, x, y, ICON_KEYS.FIRE_ANT) || this;
        _this.visionRange = 50;
        _this.attackDamage = 10;
        _this.currentTarget = null;
        _this.lastAttackTime = 0;
        scene.add.existing(_this);
        scene.physics.add.existing(_this);
        _this.speed = speed;
        _this.health = health;
        _this.fireAntState = fireAntState;
        _this.visionRange = visionRange;
        _this.attackDamage = attackDamage;
        _this.detectionCircle = new DetectionCircle(scene, _this.x, _this.y, _this.visionRange * 10, _this);
        _this.lungeCircle = new DetectionCircle(scene, _this.x, _this.y, _this.visionRange * 2, _this);
        _this.attackCircle = new DetectionCircle(scene, _this.x, _this.y, _this.visionRange, _this);
        return _this;
    }
    FireAnt.prototype.getDetectionCircle = function () {
        return this.detectionCircle;
    };
    FireAnt.prototype.getLungeCircle = function () {
        return this.lungeCircle;
    };
    FireAnt.prototype.getAttackCircle = function () {
        return this.attackCircle;
    };
    /**
     * CONFIG
     */
    FireAnt.prototype.update = function () {
        this.detectionCircle.setPosition(this.x, this.y);
        this.attackCircle.setPosition(this.x, this.y);
        this.lungeCircle.setPosition(this.x, this.y);
        switch (this.fireAntState) {
            case FireAntState.WAITING_FOR_TARGET:
                // Do nothing
                break;
            case FireAntState.TARGET_FOUND:
                this.startCrawlingToTarget();
                break;
            case FireAntState.CRAWLING_TO_TARGET:
                this.crawlToTarget();
                break;
            case FireAntState.TARGET_IN_LUNGE_RANGE:
                this.startLungeAtTarget();
                break;
            case FireAntState.LUNGING_TO_TARGET:
                this.lungeAtTarget();
                break;
            case FireAntState.TARGET_IN_ATTACK_RANGE:
                this.startAttackAtTarget();
                break;
            case FireAntState.ATTACKING_TARGET:
                this.attackCurrentTarget();
                break;
        }
    };
    /**
     * ANT BODY
     */
    FireAnt.prototype.startAttackAtTarget = function () {
        this.updateFireAntState(FireAntState.ATTACKING_TARGET);
    };
    FireAnt.prototype.attackCurrentTarget = function () {
        var currentTime = this.scene.time.now;
        if (this.currentTarget && currentTime - this.lastAttackTime >= 1000) {
            this.lastAttackTime = currentTime;
            console.log("Attacking target");
            var killedTarget = this.currentTarget.receiveAttack(this.attackDamage, this);
            if (killedTarget) {
                this.currentTarget = null;
                this.updateFireAntState(FireAntState.WAITING_FOR_TARGET);
            }
            else {
                this.updateFireAntState(FireAntState.TARGET_FOUND);
            }
        }
    };
    FireAnt.prototype.startLungeAtTarget = function () {
        this.updateFireAntState(FireAntState.LUNGING_TO_TARGET);
    };
    FireAnt.prototype.lungeAtTarget = function () {
        if (!this.currentTarget) {
            throw new Error("Lunging to target but no target found");
        }
        this.lungeTowards(this.currentTarget, this.speed * 1.5);
        this.scene.time.delayedCall(500, this.checkLungeOutcome, [], this);
    };
    FireAnt.prototype.checkLungeOutcome = function () {
        // If we are still lunging, that means the lunge missed, so we should reset the state.
        if (this.fireAntState === FireAntState.LUNGING_TO_TARGET) {
            if (this.currentTarget) {
                this.updateFireAntState(FireAntState.TARGET_FOUND);
            }
            else {
                this.updateFireAntState(FireAntState.WAITING_FOR_TARGET);
            }
        }
    };
    FireAnt.prototype.startCrawlingToTarget = function () {
        this.updateFireAntState(FireAntState.CRAWLING_TO_TARGET);
    };
    FireAnt.prototype.crawlToTarget = function () {
        if (!this.currentTarget) {
            throw new Error("Crawling to target but no target found");
        }
        this.scene.physics.moveToObject(this, this.currentTarget, this.speed);
    };
    FireAnt.prototype.lungeTowards = function (target, speed) {
        var _this = this;
        this.scene.physics.moveToObject(this, target, speed);
        // Slow down the ant after 1 second (1000 ms)
        this.scene.time.delayedCall(250, function () {
            _this.setVelocity(0, 0);
        }, [], this);
    };
    /**
     * ANT SENSES
     */
    // This is the only function that sets the current target
    FireAnt.prototype.senseAntInVisionRange = function (target) {
        var cares = this.checkIfFireAntCares(target, FireAntState.TARGET_FOUND);
        if (cares) {
            this.currentTarget = target;
            this.updateFireAntState(FireAntState.TARGET_FOUND);
        }
    };
    FireAnt.prototype.senseAntInLungeRange = function (ant) {
        var cares = this.checkIfFireAntCares(ant, FireAntState.TARGET_IN_LUNGE_RANGE);
        if (cares) {
            this.updateFireAntState(FireAntState.TARGET_IN_LUNGE_RANGE);
        }
    };
    FireAnt.prototype.senseAntInAttackRange = function (ant) {
        var cares = this.checkIfFireAntCares(ant, FireAntState.TARGET_IN_ATTACK_RANGE);
        if (cares) {
            this.updateFireAntState(FireAntState.TARGET_IN_ATTACK_RANGE);
        }
    };
    FireAnt.prototype.checkIfFireAntCares = function (ant, newState) {
        var _a;
        switch (newState) {
            case FireAntState.WAITING_FOR_TARGET:
                return true;
            case FireAntState.TARGET_FOUND:
                // If we don't have a current target, we care about this new target
                if (!this.currentTarget) {
                    return true;
                }
                return false;
            case FireAntState.TARGET_IN_LUNGE_RANGE:
                // If the ant in the lunge range is our current target, we care about it
                if (((_a = this.currentTarget) === null || _a === void 0 ? void 0 : _a.id) === ant.id) {
                    return true;
                }
                return false;
            case FireAntState.TARGET_IN_ATTACK_RANGE:
                // If the ant in the attack range is our current target, we care about it
                if (this.currentTarget === ant) {
                    return true;
                }
                return false;
            default:
                // By default, we don't care about the target
                return false;
        }
    };
    /**
     * ANT STATE
     */
    FireAnt.prototype.updateFireAntState = function (newState) {
        var invalidTransitions = {
            WAITING_FOR_TARGET: [],
            TARGET_FOUND: [],
            CRAWLING_TO_TARGET: [FireAntState.TARGET_FOUND],
            TARGET_IN_LUNGE_RANGE: [],
            LUNGING_TO_TARGET: [FireAntState.TARGET_IN_LUNGE_RANGE],
            TARGET_IN_ATTACK_RANGE: [],
            ATTACKING_TARGET: [FireAntState.TARGET_IN_ATTACK_RANGE],
        };
        if (newState === this.fireAntState) {
            // console.warn("Redundant attempt to update game state", newState);
            return;
        }
        if (invalidTransitions[this.fireAntState].includes(newState)) {
            // console.warn(
            //   `Invalid transition attempt from ${this.fireAntState} to ${newState}`
            // );
            return;
        }
        this.fireAntState = newState;
        // console.log("Updating FireAnt state", newState);
        // this.scene.events.emit(CUSTOM_EVENTS.GAME_STATE_UPDATED, this.fireAntState);
    };
    return FireAnt;
}(Phaser.Physics.Arcade.Sprite));
export default FireAnt;
